import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100')
];

export const server_loads = [7,10,13,12,14,15,16,18,19,17,20,21,22,23,24,6,3,25,26,27,29,30,31,32,33,34,35,4,38,36,37,39,9];

export const dictionary = {
		"/(site)": [55,[7],[8]],
		"/(site)/about": [57,[7,10],[8]],
		"/(site)/blog": [~58,[7],[8,11]],
		"/(site)/blog/categories/[category]": [60,[7,,13],[8,11]],
		"/(site)/blog/[slug]": [59,[7,,12],[8,11]],
		"/(site)/brand": [61,[7,14],[8]],
		"/(site)/careers": [62,[7,15],[8]],
		"/(site)/change-log": [63,[7,16],[8]],
		"/(site)/change-log/product-area/[area]": [65,[7,16,18],[8]],
		"/(site)/change-log/type/[type]": [66,[7,16,19],[8]],
		"/(site)/change-log/[slug]": [64,[7,16,17],[8]],
		"/(site)/cloud": [67,[7,20],[8]],
		"/(site)/code-exchange": [~68,[7],[8]],
		"/(site)/code-exchange/[slug]": [69,[7,21],[8]],
		"/(site)/community": [70,[7,22],[8]],
		"/(site)/events": [71,[7,23],[8]],
		"/(site)/events/aws-reinvent-2024": [73,[7,23],[8]],
		"/(site)/events/[slug]": [72,[7,23,24],[8]],
		"/(sign-up)/get-cloud": [48,[,6],[5]],
		"/(site)/get-cloud/aws-marketplace": [~74,[7],[8]],
		"/(site)/get-cloud/aws-test": [~75,[7],[8]],
		"/(sign-up)/get-cloud/callback": [49],
		"/(sign-up)/get-cloud/contact-information": [50,[,6],[5]],
		"/(sign-up)/get-cloud/log-in": [51,[,6],[5]],
		"/(sign-up)/get-cloud/payment-information": [52,[,6],[5]],
		"/(sign-up)/get-cloud/provisioning": [53,[,6],[5]],
		"/(sign-up)/get-cloud/waiting": [54,[,6],[5]],
		"/(home)/home": [40,[2,3]],
		"/(site)/how-it-works": [76,[7,25],[8]],
		"/(site)/in-use": [77,[7,26],[8]],
		"/(site)/in-use/[slug]": [~78,[7,26],[8]],
		"/(site)/news": [79,[7,27],[8,28]],
		"/(site)/news/[slug]": [80,[7,27,29],[8,28]],
		"/(site)/partners": [81,[7,30],[8]],
		"/(site)/preview/[entityId]": [82,[7,31,32],[8]],
		"/(site)/pricing": [83,[7],[8]],
		"/(site)/product": [84,[7,33],[8]],
		"/(site)/resources": [85,[7,34],[8]],
		"/(site)/resources/case-studies": [~86,[7,34],[8]],
		"/(site)/resources/case-studies/[slug]": [~87,[7,34],[8]],
		"/(site)/resources/guides": [~88,[7,34],[8]],
		"/(site)/resources/guides/[slug]": [~89,[7,34],[8]],
		"/(site)/resources/on-demand": [~90,[7,34],[8]],
		"/(site)/resources/on-demand/replay-2023": [~92,[7,34],[8]],
		"/(site)/resources/on-demand/replay-2024": [~93,[7,34],[8]],
		"/(site)/resources/on-demand/[slug]": [~91,[7,34],[8]],
		"/(site)/security": [94,[7,35],[8]],
		"/(set-up)/setup": [~41,[4]],
		"/(set-up)/setup/all-done": [42,[4]],
		"/(set-up)/setup/choose-an-sdk": [43,[4]],
		"/(set-up)/setup/install-temporal-cli": [44,[4]],
		"/(set-up)/setup/open-the-web-ui": [45,[4]],
		"/(set-up)/setup/run-a-sample-workflow": [46,[4]],
		"/(set-up)/setup/start-development-server": [47,[4]],
		"/(site)/startup-program-aws-for-startups": [98,[7,38],[8]],
		"/(site)/startup": [95,[7,36],[8]],
		"/(site)/startup/(startup-program-application)/apply": [96,[37]],
		"/(site)/startup/(startup-program-application)/apply/callback": [97],
		"/(site)/temporal-versus": [~99,[7],[8]],
		"/(site)/temporal-versus/cadence": [100,[7,39],[8]],
		"/(site)/[...slug]": [56,[7,9],[8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';